import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-social-buttons',
  templateUrl: './social-buttons.component.html',
  styleUrl: './social-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialButtonsComponent {}
