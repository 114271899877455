<a class="social-link tg-link" href="https://t.me/marketguruclub" target="_blank">
  <svg fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0001 26.8333C21.0877 26.8333 26.8334 21.0877 26.8334 14C26.8334 6.91235 21.0877 1.16667 14.0001 1.16667C6.91243 1.16667 1.16675 6.91235 1.16675 14C1.16675 21.0877 6.91243 26.8333 14.0001 26.8333Z"
      fill="currentColor"
    />
    <path
      d="M9.72241 14.5347L11.9377 18.4876C11.9377 18.4876 12.085 18.8124 12.2323 18.8124C12.3797 18.8124 14.6713 16.196 14.6713 16.196L18.278 9.72223L10.8328 14.0848L9.72241 14.5347Z"
      fill="#A2A2BA"
    />
    <path
      d="M12.5739 15.6041L12.3989 18.4265C12.3989 18.4265 12.3276 19.2949 12.8916 18.4265C13.4556 17.5581 14.0002 16.8969 14.0002 16.8969"
      fill="#7A7A94"
    />
    <path
      d="M9.87735 15.0878L6.74516 14.0655C6.74516 14.0655 6.37539 13.9132 6.49502 13.5652C6.51677 13.4891 6.57115 13.4347 6.72341 13.3259C7.41945 12.8474 19.5675 8.47539 19.5675 8.47539C19.5675 8.47539 19.9156 8.35576 20.1113 8.43189C20.2092 8.46451 20.2636 8.50802 20.318 8.6494C20.3397 8.70378 20.3506 8.81253 20.3506 8.92129C20.3506 8.99742 20.3397 9.07355 20.3288 9.19318C20.2527 10.3895 18.045 19.2967 18.045 19.2967C18.045 19.2967 17.9145 19.8187 17.4359 19.8404C17.2619 19.8513 17.0553 19.8078 16.8051 19.5903C15.8698 18.7855 12.6506 16.6212 11.9437 16.1536C11.9002 16.1318 11.8893 16.0883 11.8893 16.0557C11.8785 16.0013 11.9328 15.947 11.9328 15.947C11.9328 15.947 17.5447 10.9659 17.6861 10.4439C17.6969 10.4004 17.6534 10.3786 17.5991 10.4004C17.2293 10.5418 10.7691 14.6093 10.0622 15.066C9.99698 15.1095 9.87735 15.0878 9.87735 15.0878Z"
      fill="white"
    />
  </svg>
</a>
<a class="social-link yt-link" href="https://youtube.com/channel/UCJ5GIk9u1PE6IPDTwXmCimw" target="_blank">
  <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      clip-rule="evenodd"
      d="M20.595 5.41774C21.5413 5.64806 22.2875 6.32548 22.54 7.18581C23 8.74613 23 12 23 12C23 12 23 15.2539 22.54 16.8142C22.2875 17.6745 21.5413 18.3519 20.595 18.5823C18.88 19 12 19 12 19C12 19 5.12 19 3.405 18.5823C2.45875 18.3519 1.7125 17.6745 1.46 16.8142C1 15.2539 1 12 1 12C1 12 1 8.74613 1.46 7.18581C1.7125 6.32548 2.45875 5.64806 3.405 5.41774C5.12 5 12 5 12 5C12 5 18.88 5 20.595 5.41774ZM15.5 12L9.75 9.04532V14.9547L15.5 12Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</a>
