<div class="footer">
  <div class="footer__top">
    <div class="container">
      <div class="footer__row">
        <div>
          <mg-logo [height]="'4.4rem'" class="d-block" />
          <div class="footer__logos">
            <app-authorized-button />
            <a href="https://navigator.sk.ru/orn/1125116" target="_blank">
              <img alt="Sk" src="assets/images/sk-logo-small.svg" />
            </a>
          </div>
        </div>

        <div>
          <app-contacts-buttons class="mb-16" line />

          <div class="footer__socials footer__socials--mobile">
            <div class="footer__socials_title">Присоединяйтесь к&nbsp;нам:</div>
            <app-social-buttons />
          </div>

          <div class="footer__contacts">
            <div class="footer__contacts_header">Юридические данные</div>
            <div>
              ООО «МАРКЕТГУРУ»
              <br />Общество с ограниченной ответственностью «МаркетГуру» <br /><span>ИНН:</span> 61541&zwj;60950
              <br /><span>ОГРН:</span> 1216100023897
            </div>
          </div>
        </div>

        <div>
          <div class="footer__socials">
            <div class="footer__socials_title">Присоединяйтесь к&nbsp;нам:</div>
            <app-social-buttons />
          </div>
          <div class="footer__contacts footer__contacts--last">
            <div class="footer__contacts_header">Адрес</div>
            <div>
              347904, Россия, Ростовская область,<br />г. Таганрог, пл. Восстания, д. 3, к. 2,<br />этаж/литер 2/А
              пом.3
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div class="container">
      <div class="footer__bottom_row">
        <div>&copy; {{ currentYear }} MarketGuru inc.</div>
        <div class="footer__bottom_links">
          <a routerLink="/confidentiality">Политика конфиденциальности</a>
          <a routerLink="/privacy">Публичная оферта</a>
        </div>
      </div>
    </div>
  </div>
</div>
